
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

body{
  background-image: url("embed.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  /* display: flex; */
  font-family: 'Roboto', sans-serif; 
}

.active{
  @apply text-indigo-600 scale-125
}